export const getBase64Url = (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    var base64 = new Promise(function (resolve, reject) {
      reader.onload = (event) => {
        if (event.target)
          resolve(event.target.result);
        else
          reject(new Error('fail'))
      };
    });
    return base64;
  }

  export const b64toBlob = (dataURI, type) => {
    let byteString = atob(dataURI.split(',')[1]);
    const _ab = new ArrayBuffer(byteString.length);
    var _ia = new Uint8Array(_ab);
    for (var i = 0; i < byteString.length; i++) {
      _ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([_ab], { type: type });
  }