import Vue from "vue";
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import { 
    required, 
    // minLength, 
    // between 
} from 'vuelidate/lib/validators';

function isUserName(value) {
    if (!value) return true;
    return new RegExp(/^[a-zA-Z ]*$/g).test(value);
}

function isMobileNumber(value) {
    if (!value) return true;
    return new RegExp(/^[0][6-9]\d{9}$|^[6-9]\d{9}$/g).test(value);
}

function isAlphabet(value) {
    if (!value) return true;
    return new RegExp(/^[a-zA-Z ]*$/g).test(value);
}

export {
    isUserName,
    isMobileNumber,
    isAlphabet,
    required
}

