<template>
  <div class="product-regcnt">
    <header class="inner-header">
      <img src="../assets/images/backIcon.svg" alt="" @click="$router.go(-1)">
      <h2>
        {{ $t('Enter Ad Details') }}
      </h2>
      <button class="checkTC" @click="$router.push('/terms-and-conditions')">{{ $t('CheckTnC') }}</button>
    </header>

    <section class="mid-cnt">
      <form @submit.prevent="createAd" class="fomr-box">
        <div class="field-row">
          <label class="text-label">
            {{ $t('CreateAds') }}
          </label>
          <div class="adsType">
            <label for="shops" class="adsTypeOptions">
              <input type="radio" id="shops" name="adsType" value="Shops" v-model="adsType"> <span>{{ $t('Shop') }}</span>
            </label>
            <label for="products" class="adsTypeOptions">
              <input type="radio" id="products" name="adsType" value="Products" v-model="adsType"> <span>{{ $t('Product') }}</span>
            </label>
          </div>
        </div>
        <div class="field-row">
          <label class="text-label">{{ $t('Title') }}</label>
          <input
            type="text"
            name="title"
            value=""
            class="input-style"
            :placeholder="$t('EnterTitle')"
            v-model="title"
            @blur="$v.title.$touch()"
          />

          <!-- v-model="title" -->
          <span class="error-msg" v-if="$v.title.$error">{{ $t('TitleRequired') }}</span>
          <div class="note-msg">
            {{ $t('50 character left') }}
          </div>
        </div>

        <div class="field-row">
          <label class="text-label">{{ $t('SelectShop') }}</label>
          <div class="pos-relate">
            <select
              @change="getProductsOfShop(selectedShopId)"
              class="select-opt"
              v-model="selectedShopId"
              @blur="$v.selectedShopId.$touch()"
            >
              <option value="" disabled>{{ $t('SelectShop') }}</option>
              <option
                v-for="(item, index) in shopsListing"
                :value="item.shop_id"
                :key="index"
                >{{ item.name }}</option
              >
            </select>

            <span class="drop-icon">
              <img src="../assets/images/dropIcon.svg" alt="">
            </span>
          </div>
          <span class="error-msg" v-if="$v.selectedShopId.$error">{{ $t('ShopRequired') }}</span>
        </div>

        <div
          class="field-row"
          v-if="adsType === 'Products'"
          @click="selectedShopId !== '' ? showProductListing = true : showProductListing = false"
        >
          <div
            v-if="!selectedProduct.hasOwnProperty('itemDTOList')"
            class="pos-relate custom-tab"
          >
            {{ $t('SelectProduct') }}
            <span class="drop-icon">
              <img src="../assets/images/dropIcon.svg" alt="">
            </span>
          </div>
          <span class="error-msg" v-if="!selectedProduct.hasOwnProperty('itemDTOList')">{{
            formError.product
          }}</span>
        </div>

        <div
          class="field-row"
          v-if="adsType == 'Products' && selectedProduct.hasOwnProperty('itemDTOList')"
        >
          <label class="text-label">{{ $t('SelectedProduct') }}</label>
          <div class="selected-prdtitem">
            <span class="prdt-icon">
              <img
                :src="
                  selectedProduct.itemDTOList[0].itemMediaDTOList.length
                    ? selectedProduct.itemDTOList[0].itemMediaDTOList[0]
                        .thumbnail
                    : null
                "
                alt=""
              />
            </span>

            <div class="prdt-info">
              <p>{{ selectedProduct.itemDTOList[0].itemName }}</p>
              <div class="price-block">
                <span class="update-price"
                  >₹{{ selectedProduct.itemDTOList[0].mrp }}</span
                >
              </div>
            </div>

            <span class="edit-product" @click="showProductListing = true">
              <img src="../assets/images/editIcon.svg" alt="">
            </span>
          </div>
        </div>
        <div class="grid-block">
          <div class="grid-col">
            <div class="field-row">
              <div class="date">
                <label class="text-label" for="">
                  {{ $t('Start Date') }}
                </label>
                <datetime 
                    type="datetime"
                    v-model="startDate"
                    :min-datetime="new Date().toISOString()"
                    @blur="$v.startDate.$touch()"
                    auto
                >
                </datetime>
              </div>
            <span class="error-msg" v-if="$v.startDate.$error">{{ $t('StartDateRequired') }}</span>
            </div>
          </div>
          <div class="grid-col">
            <div class="field-row">
              <div class="date">
                <label class="text-label" for=""> {{ $t('End Date') }} </label>
                <datetime 
                  type="datetime"
                  v-model="endDate"
                  :min-datetime="startDate"
                  @blur="$v.endDate.$touch()"
                  auto
                >
                </datetime>
              </div>
              <span class="error-msg" v-if="$v.endDate.$error">{{ $t('EndDateRequired') }}</span>
            </div>
          </div>
        </div>
        <div class="field-row">
          <label class="text-label">{{ $t('Description') }}</label>
          <textarea
            class="textare-style"
            :placeholder="$t('EnterDescription')"
            name="description"
            v-model="description"
            @blur="$v.description.$touch()"
          ></textarea>
          <!-- v-model="description" -->
          <span class="error-msg" v-if="$v.description.$error">{{ $t('DescriptionRequired') }}</span>
          <div class="note-msg">
            {{ $t('250 character left') }}
          </div>
        </div>
        <div class="field-row" v-if="adsType === 'Shops'">
          <label class="text-label">{{ $t('Product Image') }}</label>
          <span class="img-preview" v-if="cropped"><img :src="cropped"/></span>
          <vue-croppie
            v-if="fileValue"
            ref="croppieRef"
            :enableOrientation="true"
            :boundary="{ width: '100%', height: '200' }"
            :viewport="{ width: '100%', height: 146, type: 'square' }"
          >
          </vue-croppie>
          <div v-if="fileValue" class="crop-btnbox">
            <button class="button" @click.prevent="crop">Crop Image</button>
          </div>
          <div class="input-placebox">
            <input class="inputfile" type="file" @change="croppie" />
            {{ $t('Upload Image') }}
            <span class="icon-Btn">
              <img src="../assets/images/upload-icon.svg" alt="" />
            </span>
          </div>
        </div>
      </form>

      <div class="ftr-form">
        <button :class="disable ? 'btn disable' : 'btn'" @click="createAd">
          {{ $route.query.AdId ? $t('Update Ad') : $t('Create Ad') }}
        </button>
      </div>
    </section>
    <loadingIcon v-if="loading" />
    <Toaster :dataValue="toasterData" />
    <ProductListModal
      @input="setProductId"
      :shop-id="selectedShopId.toString()"
      :selected-product-id="
        selectedProduct.hasOwnProperty('itemDTOList')
          ? selectedProduct.itemDTOList[0].sellerItemRelationshipId
          : ''
      "
      @close="showProductListing = false"
      v-if="showProductListing"
    />
  </div>
</template>
<script>
import Vue from "vue";
import { Datetime } from 'vue-datetime';
import "vue-datetime/dist/vue-datetime.css";
Vue.use(Datetime);
import VueCroppie from 'vue-croppie';
import 'croppie/croppie.css'; // import the croppie css manually
Vue.use(VueCroppie);
import { 
    required
} from '../utils/validators';
import { b64toBlob } from '../utils/helper';
import loadingIcon from '../components/icons/loadingIcon';
import Toaster from '../components/Toaster';
import axios from 'axios';
// import Vue from 'vue';
import { mapActions } from 'vuex';
// import PaginatedSelection from '@/components/PaginatedSelection';
import ProductListModal from '@/components/ProductListModal';
import ApiRequest from '../services/ApiRequest';
import { BASE_API_URL, FILE_UPLOAD_URL} from '../utils/constants';
import { SessionHelper } from '../services/Session';
export default {
  name: 'CreateAdForm',
  components: {
    // PaginatedSelection,
    loadingIcon,
    Datetime,
    Toaster,
    ProductListModal,
  },
  async mounted() {
    // this.pincodesList = this.$store.state.pincodesList;
    await this.EditFormPreFilledValue();
    await this.getLocalMallShops();
    // setTimeout(() => {}, 5000);
    // const response = await this.getLocalMallProductsOfShop({
    //   page: 0,
    //   id: this.selectedShopId,
    // });
    // console.log(response);
  },
  data() {
    return {
      webUrl: '',
      mediaType: 1,
      actionApplicationId: null,
      startDate: "",
      endDate: "",
      disabledDates: {},
      title: '',
      description: '',
      sellingPrice: '',
      imagePreview: null,
      imagePreviewBase64: null,
      mobileNumber: '',
      action: null,
      formError: {
        title: null,
        description: null,
        webUrl: null,
        mobileNumber: null,
        shop: null,
        product: null,
        imagePreview: null,
      },
      disable: false,
      toasterData: {},
      isNameRequired: false,
      isEmailRequired: false,
      isPhoneNumberRequired: false,
      isPincodeRequired: false,
      loading: false,
      croppieImage: '',
      cropped: null,
      fileValue: null,
      pincodesList: [],
      adsType: 'Shops',
      shopsListing: [],
      selectedShopId: '',
      selectedProductId: '',
      productDTOs: [],
      userData: null,
      showProductListing: false,
      selectedProduct: {},
    };
  },
  validations: {
    title: {
      required
    },
    selectedShopId: {
      required
    },
    startDate: {
      required
    },
    endDate: {
      required
    },
    description: {
      required
    }
  },
  methods: {
    ...mapActions({
      getLocalMallShopsList: 'getLocalMallShopsList',
      getLocalMallProductsOfShop: 'getLocalMallProductsOfShop',
    }),
    setProductId(value) {
      this.selectedProduct = value;
      this.selectedProductId = value.itemDTOList[0].sellerItemRelationshipId;
      this.showProductListing = false;
    },
    getProductsOfShop(id) {
      const shop = this.shopsListing.find((shop) => shop.shop_id === id);
      if (shop) this.pincodesList = [shop.address.zip_code];
      if (this.adsType === 'Shops') return;
      if(this.selectedShopId !== '') this.selectedProduct = {};
    },
    getLocalMallShops() {
    //   const vm = this;
      this.getLocalMallShopsList().then((response) => {
        this.shopsListing = response;
      });
    },

    createAd() {
        if(this.$v.$invalid){
            this.$v.$touch();
        }
        else{
            this.disable = true;
            this.createPayload();
        }
    },

    createPayload() {
        let startDate = new Date(this.startDate).getTime();
        let endDate = new Date(this.endDate).getTime();
        let obj = {
          name: this.title,
          description: this.description,
          startDate,
          endDate,
          pincodes: this.pincodesList,
          mediaType: this.mediaType,
          shopId: this.selectedShopId,
        };
        if (this.adsType === 'Products') {
          obj.mediaType = 3;
          obj.shopSellerItemRelationshipId = this.selectedProductId;
        }
        if (this.adsType === 'Shops' && this.imagePreview) {
          obj.campaignMedia = [
            {
              mediaURL: this.imagePreview,
            },
          ];
        }
        const { AdId } = this.$route.query;
        if (AdId) {
          ApiRequest(BASE_API_URL, `/campaign/details/${AdId}`, "get").then((data) => {
            let detail = data.data;
            console.log('=if dispatch edit', obj);
            obj['campaignIdentifier'] = detail.campaignIdentifier;
            obj.campaignAction = detail.campaignAction;
            obj.productMedia = detail.productMedia;
            const shop = this.shopsListing.find(
              (shop) => shop.shop_id === this.selectedProductId
            );
            if (shop) this.pincodesList = [shop.address.zip_code];
            if(this.adsType === 'Products' && !this.selectedProduct.itemDTOList){
              this.formError.product = this.$t('ProductRequired');
              this.disable = false;
            }
            else if(this.startDate == this.endDate){
              this.toasterData = {
                status: true,
                msg: this.$t('StartDateEndDateCannotEqual'),
                timeoutMs: '5000',
                color: '#ff6600',
              };
            }
            else{
              this.updateAd(obj);
            }
          })
        } else {
          const shop = this.shopsListing.find(
            (shop) => shop.shop_id === this.selectedProductId
          );
          if (shop) this.pincodesList = [shop.address.zip_code];
          console.log('===else dispatch normal-', obj);
          if(this.adsType === 'Products' && !this.selectedProduct.itemDTOList){
            this.formError.product = this.$t('ProductRequired');
            this.disable = false;
          }
          else if(this.startDate == this.endDate){
              this.toasterData = {
                status: true,
                msg: this.$t('StartDateEndDateCannotEqual'),
                timeoutMs: '5000',
                color: '#ff6600',
              };
              this.disable = false;
            }
          else{
            console.log(obj);
            this.dispatchCreateAds(obj);
          }
        }
    },

    dispatchCreateAds(payload) {
        ApiRequest(BASE_API_URL,`/campaign/add-ads`, "post", payload).then((data) => {
          console.log('-data-dispatch-', data);
          if (data.httpStatus) {
            this.disable = false;
            this.loading = false;
            if (data.httpStatus === 200) {
              this.$router.replace('/dashboard');
              this.$store.commit('PincodeList', []);
            }
            this.toasterData = {
              status: true,
              msg: data.message,
              timeoutMs: '5000',
              color: '#ff6600',
            };
            if (data.message === 'Invalid Token') {
              console.log('=token error==');
              SessionHelper.refereshJwtToken();
              if (SessionHelper.getCookie('JWT-TOKEN') !== 'null') {
                this.dispatchCreateAds();
              }
            }
          }
        })
        .catch(() => {
          this.disable = false;
        });
    },

    updateAd(payload) {
        ApiRequest(BASE_API_URL,`/campaign/update-ads`, "put", payload).then((data) => {
          if (data.httpStatus) {
            this.disable = false;
            this.loading = false;
            if (data.httpStatus === 200) {
              this.$router.replace('/dashboard');
            }
            this.toasterData = {
              status: true,
              msg: data.message,
              timeoutMs: '5000',
              color: '#ff6600',
            };
            if (data.message === 'Invalid Token') {
              console.log('=token error==');
              SessionHelper.refereshJwtToken();
              if (SessionHelper.getCookie('JWT-TOKEN') !== 'null') {
                this.updateAd();
              }
            }
          }
        })
        .catch((err) => {
          this.disable = false;
          console.log('', err.response);
        });
    },
    async uploadImage(file) {
      let formData = new FormData();
      formData.append('image', file, file.type);
      formData.append('isResizeRequired', true);
      axios
        .post(FILE_UPLOAD_URL, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((data) => {
          this.imagePreview = data.data.mediaVariantDTOs[0].url;
          this.formError.imagePreview = null;
        })
        .catch(() => {
          // toaster(this, this.$t("wentWrongMsg"), "top-left", 2000);
        });
    },
    croppie(e) {
      this.fileValue = e.target.files;
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      var reader = new FileReader();
      reader.onload = (e) => {
        this.$refs.croppieRef.bind({
          url: e.target.result,
        });
      };

      reader.readAsDataURL(files[0]);
    },
    crop() {
      // Options can be updated.
      // Current option will return a base64 version of the uploaded image with a size of 600px X 450px.
      let options = {
        type: 'base64',
        size: { width: 340, height: 150 },
        format: 'jpeg',
      };
      this.$refs.croppieRef.result(options, (output) => {
        this.cropped = this.croppieImage = output;
        const signBlob = b64toBlob(this.cropped, 'image/png');
        this.uploadImage(signBlob);
      });
    },

    EditFormPreFilledValue() {
      const { AdId } = this.$route.query;
      // console.log(detail);
      if (AdId) {
        ApiRequest(BASE_API_URL, `/campaign/details/${AdId}`, "get").then((data) => {
          let detail = data.data;
          console.log(detail);
          this.title = detail.name;
          this.description = detail.description;
          this.startDate = new Date(detail.startDate).toISOString();
          this.endDate = new Date(detail.endDate).toISOString();
          this.selectedShopId = detail.shopId;
          this.setProductDetails(detail);
          this.pincodesList = detail.pincodes;
          this.setImage(detail);
          this.setMediaType(detail);
        })
      }
    },

    setProductDetails(detail) {
      if (detail.shopSellerItemRelationshipId) {
        this.adsType = 'Products';
        document.getElementById("shops").disabled = true;
        this.selectedProductId = detail.shopSellerItemRelationshipId;
        this.getProductsOfShop(detail.shopId);
      }
      document.getElementById("products").disabled = true;
    },

    setImage(detail){
      if (detail.campaignMedia.length) {
        this.imagePreview = detail.campaignMedia[0].mediaURL;
        this.cropped = detail.campaignMedia[0].mediaURL;
      }
    },

    setMediaType(detail){
      if (detail.mediaType) {
        this.mediaType = detail.mediaType;
      }
    }
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/product-register";
@import "@/assets/scss/create-ads";
</style>