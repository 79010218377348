<template>
  <div class="product-modal">
    <div class="modal-head">
      <h2>
        Select Product
      </h2>
      <span @click="$emit('close')" class="icon-btn">
        <img src="../assets/images/closeIcon.svg" alt="">
      </span>
    </div>
    <div class="modal-mid">
      <ul class="prdt-List" ref="productList">
        <li
          v-for="(item, index) in options"
          :class="{
            'selected-prdt':
              currIndex === item.itemDTOList[0].sellerItemRelationshipId,
          }"
          :key="index"
          @click="selectProduct(item, index)"
        >
          <span class="prdt-icon">
            <img
              :src="
                item.itemDTOList[0].itemMediaDTOList.length
                  ? item.itemDTOList[0].itemMediaDTOList[0].thumbnail
                  : null
              "
            />
          </span>

          <div class="prdt-info">
            <p>{{ item.itemDTOList[0].itemName }}</p>
            <div class="price-block">
              <span class="update-price">₹{{ item.itemDTOList[0].mrp }}</span>
            </div>
          </div>
        </li>
        <p style="text-align: center" v-if="!hasNextPage">No more products</p>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  name: 'ProductListModal',
  props: {
    shopId: String,
    selectedProductId: String,
  },
  data() {
    return {
      observer: null,
      page: 0,
      search: '',
      limit: 20,
      options: [],
      hasNextPage: true,
      currIndex: this.selectedProductId,
      isLoading: true,
    };
  },
  created() {
    this.getOptions();
  },
  destroyed() {
    this.$el.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$el.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapActions({
      getLocalMallProductsOfShop: 'getLocalMallProductsOfShop',
    }),
    selectProduct(item) {
      this.currIndex = item.itemDTOList[0].sellerItemRelationshipId;
      this.$emit('input', item);
    },
    handleScroll() {
      const { scrollTop, scrollHeight, clientHeight } = this.$el;
      if (scrollTop + clientHeight >= scrollHeight && this.hasNextPage)
        this.getOptions();
    },
    async getOptions() {
      this.isLoading = true;
      setTimeout(() => {}, 5000);
      const response = await this.getLocalMallProductsOfShop({
        page: this.page,
        id: this.shopId,
      });
      console.log(response);
      // // const response = await axios.get(`https://api.instantwebtools.net/v1/passenger?page=${this.page}&size=${this.limit}`)
      if (!response.productDTOs && !response.productDTOs.length == 0) this.hasNextPage = false;
      else {
        if (response && response.productDTOs.length < this.limit) this.hasNextPage = false;
        this.options.push(...response.productDTOs);
      }
      this.isLoading = false;

      this.page++;
      // this.options = this.options.concat(response['data']['data'])
    },
  },
};
</script>
<style scoped>
.product-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  background: #f4f6f8;
  z-index: 111;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  overflow-y: auto;
  animation: slideTop 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@-webkit-keyframes slideTop {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes slideTop {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1;
  }
}

.modal-head {
  width: 100%;
  height: auto;
  padding: 16px 32px 16px 16px;
  position: sticky;
  top: 0px;
  left: 0px;
  display: block;
  background: #fff;
  z-index: 11;
}

.modal-head .icon-btn {
  width: 25px;
  height: 25px;
  position: absolute;
  left: auto;
  top: 12px;
  right: 12px;
}

.modal-head .icon-btn svg {
  width: 15px;
  height: 15px;
  fill: #000;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.modal-head h2 {
  display: block;
  font-size: 1em;
  color: #505050;
}

.modal-mid {
  width: 100%;
  display: block;
  padding: 16px;
}

.prdt-List li {
  width: 100%;
  height: auto;
  padding: 8px 32px 8px 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
  margin-bottom: 16px;
  display: flex;
  position: relative;
}

.prdt-List li::after {
  width: 16px;
  height: 16px;
  background: #fff;
  border-radius: 100px;
  border: 1px solid #ddd;
  content: '';
  position: absolute;
  left: auto;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.prdt-List li.selected-prdt::after {
  border: 1px solid #108805;
}

.prdt-List li.selected-prdt::before {
  width: 12px;
  height: 12px;
  background: #108805;
  border-radius: 100px;
  position: absolute;
  left: auto;
  top: 50%;
  transform: translateY(-50%);
  right: 11px;
  content: '';
  z-index: 1;
}

.prdt-List li .prdt-icon {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
  border: 1px solid #ddd;
  border-radius: 4px;
  flex-shrink: 0;
  flex-grow: 0;
  overflow: hidden;
}

.prdt-List li .prdt-icon img {
  width: 46px;
  height: 46px;
  object-fit: cover;
}

.prdt-List li .prdt-info {
  width: calc(100% - 48px);
  padding: 0 12px;
}

.prdt-List li .prdt-info p {
  font-size: 0.875em;
  color: #505050;
  display: block;
  font-weight: 500;
  margin-top: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.prdt-List li .price-block {
  width: 100%;
  margin-top: 4px;
}

.prdt-List li .price-block span {
  font-size: 1em;
  margin-right: 4px;
}

.prdt-List li .price-block .update-price {
  font-weight: bold;
  color: #108805;
}

.prdt-List li .price-block .old-price {
  color: #727272;
  text-decoration: line-through;
}
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
